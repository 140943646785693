/* Global styles */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

body, html {
  margin: 0;
  font-family: 'Popins', sans-serif;
  color: #333;
  max-width: 100%;
}
::placeholder{
  color: white;
}
.App {
  text-align: center;
}

header {
  font-family: 'Poppins';
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 100px;
  padding-right: 100px;
  background-color: #094C2C;
  color: white;
}

header .navbar ul {
  list-style: none;
  display: flex;
  gap: 20px;
}

header .navbar ul li a {
  color: white;
  text-decoration: none;
  font-size: 18px;
}

header .signInBtn {
  background-color: transparent;
  color: white;
  border-width: 2px;
  border-color: white;
  border-radius: 10px;
  border-style: solid;
  padding: 10px 20px;
  cursor: pointer;
}

.header-credkar{
  font-family: 'Readex Pro', sans-serif;
  font-size: 40px;
  font-weight: 550;
  font-style: normal;
}
.hero, .otp-section, .lending-partners-page{
  padding-top: 50px;
  padding-bottom: 50px;
  padding-left: 100px;
  padding-right: 100px;
}
.hero {
  color: white;
  width: 100%;
  display: flex; /* Flexbox layout */
  justify-content: space-between;
  align-items: center;
  background-color: #094C2C; /* Background color */
  box-sizing: border-box;
  overflow: hidden; /* Hide any content that might overflow */
}
.hero .hero-text h1 {
  font-size: 2.5rem;
}

.hero-text-div{
  width: 60%;
  margin-top: 40px;
  margin-bottom: 40px;
  margin-right: 40px;
}
.hero-text-div-card{
    height: 30px;
    width: 450px;
    border-radius: 15px;
    background: rgba(255, 255, 255, 0.18);
    font-family: 'Poppins', sans-serif;
    font-size: 15px;
    font-weight: 400;
    align-content: center;
}
.hero-main-text{
  font-family: 'Poppins', sans-serif;
  font-size: 50px;
  font-weight: 550;
  font-style: normal;
  text-align: left;
  margin-top: 40px;
  margin-bottom: 40px;
}
.hero-sec-text{
  font-family: 'Poppins', sans-serif;
  font-size: 18px;
  font-weight: 400;
  font-style: normal;
  text-align: left;
}
.hero-image{
  width: 50%;
  border-width: 5px;
  border-color: #F0F0F0;
}
.pg1img{
  border-radius: 20px;
}
.otp-section{
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: #094C2C;
}
.otp-card-div{
  width: 80%;
  border: solid;
  border-radius: 10px;
  border-width: 1px;
  padding: 10px;
  border-color: #83B49D;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  gap: 20px;
  padding-left: 20px;
  padding-right: 20px;
  background-color: #286D4C;
  align-items: center;
}
.otp-card-text{
  font-family: 'Poppins', sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: white;
  width: 60%;
}
.otp-card-input{
  color: white;
  height: 50%;
  border: #5EA182;
  border-radius: 20px;
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  width: 18%;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.15);
}
.SendOtpButton{
  background-color: #46CA61;
  color: white;
  height: 50%;
  border-color: transparent;
  border-radius: 20px;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  width: 15%;
  text-align: center;
  cursor: pointer;
}

.lending-partners-page{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  justify-items: center;
  align-items: center;
  align-content: center;
}
.lp-main-text{
  color: #656565;
  font-family: 'Poppins', sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.lp-img-div{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  gap: 30px;
  margin-top: 20px;
}
.lp-card-div{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background-color: #F0FFF2;
  padding-top: 40px;
  padding-bottom: 40px;
  padding-left: 100px;
  padding-right: 100px;
  border-radius: 40px;
  margin-top: 40px;
}

.lp-card-text{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  gap: 50px;
}
.lp-mt{
  color: #168D54;
  text-align: center;
  font-family: Poppins;
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: center;
}
.lp-st{
  color: #4A6E5D;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.loan-features, .testimonials, .faq, .footer {
  padding: 50px;
}

.loan-features .features-grid, .loan-options .options-grid, .testimonials .testimonial-grid, .faq .faq-grid {
  display: flex;
  justify-content: space-around;
  gap: 20px;
}
.testimonials{
  background: #F6F9F8;
  display: flex;
  flex-direction: column;
}
.t1{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 20px;
}
.testimonial-fire-card{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5px;
  height: 30px;
  width: 300px;
  border: 2px solid #EAECF0;
  border-radius: 15px;
  background: white;
}
.testimonial-fire-text{
  font-family: 'Poppins', sans-serif;
  font-size: 15px;
  font-weight: 500;
  margin-top: 10px;
}
.testimonial-main-text{
  align-self: stretch;
  color: #3D3D3D;
  font-family: Poppins;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 60px */
}
.testimonial-grid{
  margin-top: 40px;
  display: inline-flex;
  flex-direction: row;
  gap: 50px;
}
.testimonial-white{
  width: 100%;
  display: flex;
  padding: 48px 62.667px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 10px;
  border-radius: 26.667px;
  background: white;
}
.testimonial-green{
  display: flex;
  padding: 48px 62.667px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 13.333px;
  border-radius: 26.667px;
  background: #004852;
  color: white;
}
.testimony{
  margin-top: 30px;
  font-family: Poppins;
  font-size: 21.333px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 32px */
}
.testimony-cred-div{
  display: flex;
  flex-direction: column;
  gap: 0px;
  justify-content: center;
  text-align: left;
}
h5{
  font-family: Poppins;
  font-size: 21.333px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
h6{
  color: #A1A1A1;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 0px;
}
.testimonial-name{
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 0px;
  margin: 0px;
}
.path{
  background: #ffffff;
  display: flex;
  flex-direction: column;
  padding-top: 100px;
}
.path-fire-card{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5px;
  height: 30px;
  width: 200px;
  border: 2px solid;
  border-color: #EAECF0;
  border-radius: 15px;
  background: #F9FAFB;
  text-align: left;
  margin-bottom: 10px;
}
.path-grid{
  margin-top: 40px;
  display: inline-flex;
  flex-direction: row;
  justify-content: space-around;
  gap: 20px;
  padding-left: 50px;
  padding-right: 50px;
}
.path-card{
  width: 100%;
  display: flex;
  padding: 20px;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  gap: 15px;
  border-radius: 26.667px;
  background: white;
  border-left: 2px solid #E4F7EE;
  border-bottom: 2px solid #E4F7EE;
}
.path-svg{
  display: flex;
  width: 84px;
  height: 84px;
  padding: 26.329px 26px 26.111px 26.439px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 84px;
  background: #E4F7EE;
}
.path-main-text{
  color: #0F5936;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: left;
}
.path-sec-text{
  align-self: stretch;
  color: #706183;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
  text-align: left;
}
.view-more-div{
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 50px;
}
.view-more-button{
  display: flex;
  width: 268.667px;
  height: 69.333px;
  padding: 5px;
  justify-content: center;
  align-items: center;
  gap: 10.667px;
  border-radius: 58.667px;
  border: 1.067px solid #40AF3E;
  color: #3F3F3F;
  font-family: Poppins;
  font-size: 21.333px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 32px */
  align-items: center;
}
.loan-options{
  padding: 100px;
  background: #0F5936;
  padding-top: 150px;
}
.lo-main-text{
  align-self: stretch;
  color: white;
  font-family: Poppins;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 60px */
}
.lo-p{
  color: #E4F7EE;
  text-align: center;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 150%; /* 30px */
  margin-top: 30px;
}
.options-grid{
  color: white;
  padding: 50px;
}
.option-card-div{
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  background: var(--color-white-solid, #FFF);
  color: black;
  justify-content: space-around;
  align-items: center;
  padding: 20px;
  box-shadow: 0 4px 6px whitesmoke
}
.option-card-main-text{
  color: #0F5936;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 36px */
  margin-top: 10px;
}
.option-card-check-btn{
  display: inline-flex;
  padding: 6px 20px 5px 19px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background: #E4F7EE;
  color: #2E8D60;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 21px */
  border: 0px;
  margin-top: 10px;
}
.our-working-img-div{
  display: flex;
  /*width: 645px;*/
  width: 70%;
  /*height: 600px;*/
  padding: 10px 10px 10px 10px;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  border: var(--stroke-weight-1, 1px) solid #E5E5E5;
  background: #F5F5F5;
  height: 100%;
  margin-left: 50px;
}
.our-working-img{
  width: 545px;
  height: 425px;
  flex-shrink: 0;
  border-radius: 15px;
  background: lightgray 0px -251.948px / 100% 151.228% no-repeat;
}
.our-working-main-div{
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding: 50px;
}
.our-working-main-card{
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: space-around;
  align-items: center;
  padding-left: 50px;
}
.our-working-card{
  display: flex;
  flex-direction: column;
  gap: 0px;
  align-content: start;
  width: 708px;
  padding: 32px 26.667px;
  align-items: flex-start;
  border-radius: 26.667px;
  background: #DEF8E1;
  box-shadow: 0 4px 6px #0F5936
}
.our-working-card-1{
  display: flex;
  flex-direction: column;
  gap: 0px;
  align-content: start;
  width: 708px;
  padding: 32px 26.667px;
  align-items: flex-start;
  border: 1px solid #E5E5E5;
  border-radius: 26.667px;
  background: white;
  box-shadow: 0 4px 6px #E5E5E5
}

.ow-h{
  color: #3D3D3D;
  font-family: Poppins;
  font-size: 26.667px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 40px */
}
.ow-p{
  color: #6B6969;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 32px */
  padding-right: 80px;
  text-align: left;
}
.option-card-div-1{
  width: 22%;
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  background: var(--color-white-solid, #FFF);
  color: black;
  justify-content: space-around;
  align-items: center;
  padding: 20px;
  box-shadow: 0 4px 6px whitesmoke
}
.footer {
  width: 100%;
  background: #F0F0F0;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  gap: 30px;
  box-sizing: border-box;
  opacity: 0.8;
}
.footer-credkar{
  font-family: 'Readex Pro', sans-serif;
  font-size: 48px;
  font-weight: 600;
  font-style: normal;
}

.footer-app-store-div{
  padding-top: 0px;
}
.footer .social-links a {
  color: white;
  margin: 0 10px;
}

.social-links{
  display: flex;
  flex-direction: row;
}
.send-feedback{
  color: black;
  font-family: Poppins;
  font-size: 18.667px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.footer-links{
  display: flex;
  flex-direction: row;
  font-family: 'Poppins',sans-serif;
  font-size: 20px;
  font-weight: 400;
  color: #646161;
  line-height: 150%; /* 32px */
}
.insta-link-div{
  background-color: #006022;
  border: solid;
  border-width: 0px;
  border-radius: 20px;
  height: 40px;
  width: 40px;
  margin-right: 40px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.insta-link{
  height: 10px;
  width: 10px;
  color: aliceblue;
}

/* Mobile phones */
@media only screen and (max-width: 600px) {
  header {
    flex-direction: column;
    align-items: center;
  }

  .hero {
    flex-direction: column;
    padding: 20px;
  }

  .hero .hero-text h1 {
    font-size: 1.5rem;
  }

  .loan-features .features-grid, 
  .loan-options .options-grid, 
  .testimonials .testimonial-grid, 
  .faq .faq-grid {
    flex-direction: column;
  }

  .footer .social-links a {
    display: block;
    margin: 10px 0;
  }
}

/* Tablets */
@media only screen and (min-width: 601px) and (max-width: 1024px) {
  header {
    padding: 20px;
  }

  .hero {
    flex-direction: column;
  }

  .loan-features .features-grid,
  .loan-options .options-grid, 
  .testimonials .testimonial-grid, 
  .faq .faq-grid {
    flex-direction: column;
    align-items: center;
  }
}

.loan-features{
  background-color: rgb(132, 207, 237);
  max-width: 100%;
}

.features-grid{
  background-color: rgb(237, 132, 202);
  max-width: 100%;
}


.options-grid{
  max-width: 100%;
}

/* Large desktops */
@media only screen and (min-width: 1025px) {
  .hero {
    padding: 100px;
    flex-direction: row;
  }

  .hero .hero-text h1 {
    font-size: 2.5rem;
  }

  .loan-features .features-grid, 
  .loan-options .options-grid, 
  .testimonials .testimonial-grid, 
  .faq .faq-grid {
    flex-direction: row;
  }
}
.hero {
  width: 100%;
}
img {
  max-width: 100%;
  height: auto;
}

/* Base layout for mobile */
.loan-features, .loan-options, .testimonials, .faq, .footer {
  padding: 100px;
}

/* Grid or Flex for larger screens */
@media only screen and (min-width: 768px) {
  .hero {
    display: flex;
    justify-content: space-between;
  }

  .loan-features .features-grid, 
  .loan-options .options-grid, 
  .testimonials .testimonial-grid, 
  .faq .faq-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 3 columns on larger screens */
    gap: 40px;
  }
}


.context {
    width: 100%;
    height: 50%;
    position: absolute;
    top:30vh;
}

.context h1{
    text-align: center;
    color: #fff;
    font-size: 50px;
    font-family: 'Popins', sans-serif;
    font-weight: 600;
}


.area{
    background: #094C2C;  
    background: -webkit-linear-gradient(to left, #8f94fb, #4e54c8);  
    width: 100%;
}

.circles{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.circles li{
    position: absolute;
    display: block;
    list-style: none;
    width: 20px;
    height: 20px;
    background: rgba(255, 255, 255, 0.2);
    animation: animate 25s linear infinite;
    bottom: -150px;
    color: white;
}

.circles li:nth-child(1){
    left: 25%;
    width: 80px;
    height: 80px;
    animation-delay: 0s;
}


.circles li:nth-child(2){
    left: 10%;
    width: 20px;
    height: 20px;
    animation-delay: 2s;
    animation-duration: 12s;
}

.circles li:nth-child(3){
    left: 70%;
    width: 20px;
    height: 20px;
    animation-delay: 4s;
}

.circles li:nth-child(4){
    left: 40%;
    width: 60px;
    height: 60px;
    animation-delay: 0s;
    animation-duration: 18s;
}

.circles li:nth-child(5){
    left: 65%;
    width: 20px;
    height: 20px;
    animation-delay: 0s;
}

.circles li:nth-child(6){
    left: 75%;
    width: 110px;
    height: 110px;
    animation-delay: 3s;
}

.circles li:nth-child(7){
    left: 35%;
    width: 150px;
    height: 150px;
    animation-delay: 7s;
}

.circles li:nth-child(8){
    left: 50%;
    width: 25px;
    height: 25px;
    animation-delay: 15s;
    animation-duration: 45s;
}

.circles li:nth-child(9){
    left: 20%;
    width: 15px;
    height: 15px;
    animation-delay: 2s;
    animation-duration: 35s;
}

.circles li:nth-child(10){
    left: 85%;
    width: 150px;
    height: 150px;
    animation-delay: 0s;
    animation-duration: 11s;
}



@keyframes animate {

    0%{
        transform: translateY(0) rotate(0deg);
        opacity: 1;
        border-radius: 0;
    }

    100%{
        transform: translateY(-1000px) rotate(720deg);
        opacity: 0;
        border-radius: 50%;
    }

}

.animation-main-div{
  height: 100%;
}